
import { defineComponent, ref, onMounted, provide, watch } from "vue";
import { NavbarProps } from "@/API/types";
import Navbar from "@/components/NavigationBar.vue";
import WordingPreview from "./Wording/WordingPreview.vue";
import {
  programTemplate,
  pgapiProgramPost,
  programProgramId,
  storeCombinability,
} from "@/API/program";
import { useStore } from "vuex";
import { AutoTableCellProps, AutoTableColProps } from "@/types";
import { formatPgmCategory, formatRequestData, getCategory } from "@/utils";
import { useRoute, useRouter } from "vue-router";
import { budgetGenerate } from "@/API/program";
import useEventbus from "@/hooks/claim/useEventbus";
import { message, Modal } from "ant-design-vue";
import { submitProgram } from "@/API/approve";
import { findbudgetExist } from "@/API/budget";
import { checkBudgetPeriod as checkBudgetPeriodAPI } from "@/API/program";
export default defineComponent({
  components: {
    Navbar,
    WordingPreview,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isAcitve = ref<number>(0);
    const { customEmit } = useEventbus();

    // 导航的数据
    const navArr = ref<NavbarProps[]>();
    navArr.value = [
      {
        title: "Design",
        path: "/programdesign/design",
        id: "1",
        query: {
          pgmId: !route.query.pgmId ? "" : route.query.pgmId,
        },
      },
      {
        title: "Budget",
        path: "/budgetinput",
        id: "2",
        query: {
          pgmId: !route.query.pgmId
            ? store.state.pgmData.programId
            : route.query.pgmId,
        },
      },
      {
        title: "Parameter",
        path: "/parametersetup",
        id: "3",
        query: {
          pgmId: !route.query.pgmId
            ? store.state.pgmData.programId
            : route.query.pgmId,
        },
      },
    ];
    // 是否折叠显示
    const isToggle = ref(true);
    const togglePosition = () => {
      isToggle.value = !isToggle.value;
    };
    const init = () => {
      // 需要清掉，不然会有上一个program的内容。那个请求两次的问题，再看看
      store.commit("updatePgmData", {});
      if (route.query?.pgmId) {
        return programProgramId({
          params: { programId: route.query.pgmId },
        }).then((res) => {
          // 更新整个Program大数据
          // res.customer = []
          store.commit("updatePgmData", res);
          // 因为category的选择放到了business UUIt的option里面了，选了哪个，才能找到对应的category
          getCategory();
          formatPgmCategory();
          store.dispatch("updateCustomerSelected");
        });
      } else {
        return programTemplate().then((res) => {
          // res.customer = []
          store.commit("updatePgmData", res);
          // 因为category的选择放到了business UUIt的option里面了，选了哪个，才能找到对应的category
          getCategory();
          formatPgmCategory();
          store.dispatch("updateCustomerSelected");
        });
      }
      /**
       *
       */
      // store.dispatch('updateCustomerSelected')
    };
    // 选择FA后。 判断对应F/A application date是否存在
    const checkFaDate = () => {
      if (
        (Object.keys(store.state.pgmData.offer).includes("fa") &&
          store.state.pgmData.offer.fa) ||
        (Object.keys(store.state.pgmData.offer).includes("fa_buy_down") &&
          store.state.pgmData.offer.fa_buy_down)
      ) {
        const periodArr = store.state.pgmData.eligibility.period.tableCell;
        const key =
          store.state.pgmData.eligibility.period.tableCellSelect.filter(
            (item: { display: string }) =>
              item.display === "F/A application date"
          )[0].id;
        let isCheckFaDate = false;
        let toDate = "";
        let fromDate = "";
        periodArr.forEach((item: any) => {
          if (item.ATTR.val === key && item.TO.val && item.FROM.val) {
            isCheckFaDate = true;
            toDate = item.TO.val;
            fromDate = item.FROM.val;
          }
        });
        if (isCheckFaDate && toDate && fromDate) {
          return true;
        } else {
          message.error("请选择F/A application date类型下的日期范围");
          return false;
        }
      } else {
        return true;
      }
    };
    const usingNewCode = ref<boolean>(false);
    // 保存通用program
    const saveAsync = async (
      resolve: Function,
      reject: Function,
      type = ""
    ) => {
      const data = await pgapiProgramPost(formatRequestData());
      if (type === "FAoffer") {
        // 更新是否升级状态
        customEmit("updateupgrade");
      }
      if (data.requireNewCode) {
        Modal.confirm({
          title: "Note",
          content: data.programCode + " program code 可用，是否更新项目编号?",
          onOk() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: true },
            })
              .then(() => {
                message.success("Save successfully!");
                if (!route.query.pgmId) {
                  router.push({
                    path: "/programdesign/design",
                    query: {
                      pgmId: store.state.pgmData.programId,
                    },
                  });
                  setTimeout(() => {
                    if (type === "FAoffer") {
                      init().then(() => {
                        resolve("success");
                      });
                    } else {
                      resolve("success");
                      window.location.reload();
                    }
                  }, 1);
                } else {
                  (type ? Promise.resolve() : init()).then(() => {
                    resolve("success");
                  });
                }
              })
              .catch((e) => {
                reject(e);
              });
          },
          cancelText: "No",
          onCancel() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: false },
            })
              .then(() => {
                message.success("Save successfully!");
                resolve("success");
              })
              .catch((e) => {
                reject(e);
              });
          },
        });
      } else {
        message.success("Save successfully!");
        if (!route.query.pgmId) {
          router.push({
            path: "/programdesign/design",
            query: { pgmId: store.state.pgmData.programId },
          });
          setTimeout(() => {
            if (type === "FAoffer") {
              init().then(() => {
                resolve("success");
              });
            } else {
              resolve("success");
              window.location.reload();
            }
          }, 1);
        } else {
          (type ? Promise.resolve() : init()).then(() => {
            resolve("success");
          });
        }
      }
    };

    const saveFAdata = async (resolve: Function, reject: Function) => {
      const data = await pgapiProgramPost(formatRequestData());
      if (data.requireNewCode) {
        Modal.confirm({
          title: "Note",
          content: data.programCode + " program code 可用，是否更新项目编号?",
          onOk() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: true },
            })
              .then(() => {
                // 更新是否升级状态
                customEmit("updateupgrade");
                message.success("Save successfully!");
                if (!route.query.pgmId) {
                  router.push({
                    path: "/programdesign/design",
                    query: {
                      pgmId: store.state.pgmData.programId,
                    },
                  });
                  setTimeout(() => {
                    init();
                  }, 1);
                } else {
                  init();
                }
                resolve("success");
              })
              .catch((e) => {
                reject(e);
              });
          },
          cancelText: "No",
          onCancel() {
            pgapiProgramPost(formatRequestData(), {
              params: { usingNewCode: false },
            })
              .then(() => {
                message.success("Save successfully!");
                resolve("success");
              })
              .catch((e) => {
                reject(e);
              });
          },
        });
      } else {
        message.success("Save successfully!");
        resolve("success");
        if (!route.query.pgmId) {
          router.push({
            path: "/programdesign/design",
            query: { pgmId: store.state.pgmData.programId },
          });
          // 更新code
          setTimeout(() => {
            init();
          }, 1);
        } else {
          init();
        }
      }
      // pgapiProgramPost(formatRequestData()).then(() => {
      //     // 更新是否升级状态
      //     customEmit("updateupgrade")
      //     if (!route.query.pgmId) {
      //         router.push({ path: '/programdesign/design', query: { pgmId: store.state.pgmData.programId}})
      //         // 更新code
      //         setTimeout(() => {
      //             init()
      //         }, 1);
      //     }
      //     message.success('Save successfully!')
      //     resolve('success')
      // }).catch(e => {
      //     reject(e)
      // })
    };
    const saveCombinabilitySetting = () => {
      storeCombinability(store.state.pgmCombinability).then(() => {
        message.success("Save successfully!");
      });
    };
    // 验证Measure没有选择
    const validationMeasure = (): boolean => {
      if (Object.keys(store.state.pgmData.offer).length > 0) {
        for (const key in store.state.pgmData.offer) {
          if (store.state.pgmData.offer[key].incentive.length > 0) {
            for (const item of store.state.pgmData.offer[key].incentive) {
              // 如果incentive有空组时, 要跳过
              if (item.groupTable !== undefined) {
                for (const val of item.groupTable) {
                  if (
                    val.tableCol.some(
                      (col: AutoTableColProps) =>
                        col.id.indexOf("Undefined") > -1
                    )
                  ) {
                    return true;
                  }
                }
              }
            }
          }
          if (store.state.pgmData.offer[key].precondition.length > 0) {
            for (const item of store.state.pgmData.offer[key].precondition) {
              if (
                item.tableCol.some(
                  (col: AutoTableColProps) => col.id.indexOf("Undefined") > -1
                )
              ) {
                return true;
              }
            }
          }
          if (store.state.pgmData.offer[key].vehicleLicense.length > 0) {
            for (const item of store.state.pgmData.offer[key].vehicleLicense) {
              if (
                item.tableCol.some(
                  (col: AutoTableColProps) => col.id.indexOf("Undefined") > -1
                )
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    };
    // 验证from, to不能交叉
    const validationFromTo = (): boolean => {
      if (Object.keys(store.state.pgmData.offer).length > 0) {
        for (const key in store.state.pgmData.offer) {
          // if (store.state.pgmData.offer[key].incentive.length > 0) {
          //     for (const item of store.state.pgmData.offer[key].incentive) {
          //         for (const val of item.groupTable) {
          //             if (val.tableCol.some((col: AutoTableColProps) => col.id.indexOf('Undefined') > -1 )) {
          //                 return true
          //             }
          //         }
          //     }
          // }
          if (store.state.pgmData.offer[key].precondition.length > 0) {
            for (const item of store.state.pgmData.offer[key].precondition) {
              for (let coli = 0; coli < item.tableCol.length; coli++) {
                let flagFrom = 0;
                if (item.tableCol[coli].id.indexOf("-to") > -1) {
                  for (let celli = 0; celli < item.tableCell.length; celli++) {
                    if (
                      item.tableCell[celli][item.tableCol[coli].id].val &&
                      item.tableCell[celli][item.tableCol[coli].id].val <=
                        flagFrom
                    ) {
                      return true;
                    } else {
                      flagFrom =
                        item.tableCell[celli][item.tableCol[coli].id].val;
                    }
                  }
                }
              }
            }
          }
          // if (store.state.pgmData.offer[key].vehicleLicense.length > 0) {
          //     for (const item of store.state.pgmData.offer[key].vehicleLicense) {
          //             if (item.tableCol.some((col: AutoTableColProps) => col.id.indexOf('Undefined') > -1 )) {
          //                 return true
          //             }
          //     }
          // }
        }
      }
      return false;
    };
    // 监听，更新组件数据到父组件
    const saveProgram: Function = (): Promise<string> => {
      return new Promise((resolve, reject) => {
        // 项目名称
        if (!store.state.pgmData.programNameCN) {
          message.error("项目名称(中文)不能为空!");
          return;
        }
        // 项目英文名
        if (!store.state.pgmData.programName) {
          message.error("Please input Program Name!");
          return;
        }
        customEmit("updateperiod");
        // period有没有报红的
        if (
          store.state.pgmData.eligibility.period.tableCell.some(
            (item: AutoTableCellProps) => item.bgcolor === true
          )
        ) {
          message.error("Please amend period!");
          return;
        }

        // Budget period 必填
        customEmit("updateBudgetPeriod");
        if (
          store.state.pgmData.budgetShowFlag &&
          (!store.state.pgmData.budgetPeriodFrom ||
            !store.state.pgmData.budgetPeriodTo)
        ) {
          message.error("Please amend Budget Period!");
          return;
        }
        //
        customEmit("updatedealer");
        // Dealer一定要选择一个
        if (
          store.state.pgmData.eligibility.dealer.tableCell.every(
            (item: AutoTableCellProps) =>
              item["Dealer.dealerType"].val.length === 0
          )
        ) {
          message.error("Please amend Dealer!");
          return;
        }
        // 验证from, to不能交叉
        // if (validationFromTo()) {
        //     message.error('From or To is Error!')
        //     return
        // }
        // 判断Measure列表头有没有选择
        if (validationMeasure()) {
          message.error("Please select the Measure");
          return;
        }
        customEmit("updatevehicle");
        customEmit("updatecalculator");
        customEmit("updatecustomer");
        customEmit("updatequota");
        customEmit("updateattachement");
        if (!checkFaDate()) {
          return;
        } else {
          customEmit("updatefa");
        }
        const isCheckoutFa = Object.keys(store.state.pgmData.offer).includes(
          "fa"
        );
        const isCheckoutFaBuyDown = Object.keys(
          store.state.pgmData.offer
        ).includes("fa_buy_down");
        if (isCheckoutFa || isCheckoutFaBuyDown) {
          setTimeout(() => {
            saveAsync(resolve, reject, "FAoffer");
          }, 1000);
        } else {
          setTimeout(() => {
            saveAsync(resolve, reject);
          }, 1000);
        }
      });
    };

    // 注入方法 子组件使用
    provide("updataprogram", saveProgram);

    // 滚动条
    const scrollTop = ref();
    // 全局监听滚动条
    const handleScroll = () => {
      scrollTop.value = document.documentElement.scrollTop;
    };
    // 悬浮导航
    const handleAnchorClick = (
      e: MouseEvent,
      link: { title: string; href: string }
    ) => {
      // 阻止点击的默认事件修改路由
      e.preventDefault();
      const srcolls = document.getElementById(link.href) as HTMLElement;
      // 滚动时
      srcolls.scrollIntoView(true);
    };
    const generateBudget = () => {
      budgetGenerate(store.state.pgmData.programId).then(() => {
        router.push({
          path: "/budgetinput",
          query: {
            pgmId: store.state.pgmData.programId,
          },
        });
      });
    };

    const checkBudgetPeriod = () => {
      customEmit("updateperiod");
      customEmit("updateBudgetPeriod");
      //
      customEmit("updatedealer");
      customEmit("updatevehicle");
      customEmit("updatecalculator");
      customEmit("updatecustomer");
      customEmit("updatequota");
      customEmit("updateattachement");
      if (checkFaDate()) {
        customEmit("updatefa");
      }
      return checkBudgetPeriodAPI(formatRequestData());
    };

    // budgeting
    const handleLink = () => {
      findbudgetExist(store.state.pgmData.programId).then((res) => {
        if (!res) {
          checkBudgetPeriod()
            .then((result) => {
              return result ? Promise.resolve() : saveProgram();
            })
            .then(() => {
              generateBudget();
            });
        } else {
          Modal.confirm({
            title: "Note",
            content:
              '"Budgeting" will update budget template and require to save all pages again.To view budget, please click "Budget" tab.',
            onOk() {
              const closeWindowEventHandler = (event: any) => {
                // Cancel the event as stated by the standard.
                event.preventDefault();

                // Chrome requires returnValue to be set.
                event.returnValue =
                  "Budget not finish Calculating, Please don't leave or close current page.";
                return "Budget not finish Calculating, Please don't leave or close current page.";
              };
              window.addEventListener("beforeunload", closeWindowEventHandler);
              checkBudgetPeriod()
                .then((result) => {
                  return result ? Promise.resolve() : saveProgram();
                })
                .then(() => {
                  generateBudget();
                });
              window.removeEventListener(
                "beforeunload",
                closeWindowEventHandler
              );
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        }
      });
    };

    const handleParameter = () => {
      router.push({
        path: "/parametersetup",
        query: {
          pgmId: store.state.pgmData.programId,
          type: "button",
        },
      });
    };

    // preview
    const visiblePreview = ref(false);
    const handlePreview = () => {
      sessionStorage.setItem("previewPgmId", store.state.pgmData.programId);
      visiblePreview.value = true;
    };

    const handleChildNav = (index: number) => {
      if (index === 1) {
        if (!store.state.pgmData.programCode) {
          message.error("Program Can't be empty!");
          return;
        }
        router.push({
          path: "/programdesign/combinabilitysetting",
          query: {
            pgmId: store.state.pgmData.programId,
          },
        });
      } else if (index === 0) {
        router.push({
          path: "/programdesign/design",
          query: {
            pgmId: store.state.pgmData.programId,
          },
        });
        init();
      }
      isAcitve.value = index;
    };
    const handleEditWording = () => {
      window.open(`${window.location.origin}/#/wordingedit`);
    };
    // wording对比
    const handleCompareWording = () => {
      window.open(`${window.location.origin}/#/wordingcompare`);
    };

    const handleSubmit = () => {
      const params = {
        program: [{ id: store.state.pgmData.programId }],
      };
      Modal.confirm({
        title: "提示",
        content: "Are you sure to save and submit?",
        onOk() {
          saveProgram().then(() => {
            submitProgram(params).then(() => {
              message.success("Submit successfully!");
            });
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };
    watch(
      () => route.path,
      (val) => {
        if (val.indexOf("/combinabilitysetting") < 0) isAcitve.value = 0;
      }
    );
    onMounted(() => {
      init();
      // 全局监听scroll-top
      window.addEventListener("scroll", handleScroll);
      isAcitve.value = window.location.href.indexOf("/design") !== -1 ? 0 : 1;
    });
    return {
      navArr,
      scrollTop,
      handleAnchorClick,
      saveProgram,
      isToggle,
      togglePosition,
      handleLink,
      handleParameter,
      handlePreview,
      visiblePreview,
      isAcitve,
      handleChildNav,
      handleSubmit,
      handleEditWording,
      handleCompareWording,
      saveCombinabilitySetting,
    };
  },
});
