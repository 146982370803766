
import { defineComponent, onMounted, ref, computed } from "vue";
import {
  wordingPreview,
  wordingInit,
  getReplacedWordingPdf,
  deleteReplacedWordingPdf,
} from "@/API/program";
import { useStore } from "vuex";
import useUploadDraw from "@/hooks/Program/useUploadDraw";
import { baseURL } from "@/API";
import { message } from "ant-design-vue";
import blobDownload from "@/utils/blobDownload";
import { useRoute } from "vue-router";
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

const typeMap = {
  0: "internal wording_EN",
  1: "external wording_EN",
  2: "external wording_CN",
};
export default defineComponent({
  name: "WordingPreview",
  props: {
    wordingpreviewvisible: Boolean,
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const previewHtml = ref();
    const activeWording = ref<number>(0);
    const fileName = ref<string>("");
    const getParams = () => {
      return {
        programId: store.state.pgmData.programId,
        type: activeWording.value === 0 ? "Internal" : "External",
        lang: activeWording.value === 2 ? "cn" : "en",
      };
    };
    const init = () => {
      const params = getParams();
      wordingPreview({ params: params }).then((res) => {
        previewHtml.value = res;
      });
      getReplacedWordingPdf({ params: params }).then((res) => {
        fileName.value = res !== null ? res.fileName : "";
      });
    };
    const { visible, fileList, headers, handleCloseUpload } =
      useUploadDraw(init);
    const handleUpload = (info: FileInfo) => {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        fileName.value = info.file.name ? info.file.name : "";
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };
    const handleClose = () => {
      context.emit("update:wordingpreviewvisible", false);
    };
    const handleEditWording = () => {
      const params = getParams();
      sessionStorage.setItem("params", JSON.stringify(params));
      window.open(`${window.location.origin}/#/wordingedit`);
    };
    // wording对比
    const handleCompareWording = () => {
      const params = getParams();
      sessionStorage.setItem("params", JSON.stringify(params));
      window.open(`${window.location.origin}/#/wordingcompare`);
    };
    const isShowEdit = computed(() => {
      if (activeWording.value < 3) {
        if (
          activeWording.value === 0 &&
          store.state.pgmData.programStatusKey === "Saved"
        ) {
          return true;
        } else if (
          activeWording.value !== 0 &&
          (store.state.pgmData.programStatusKey !== "SalesrequestCancel" ||
            store.state.pgmData.programStatusKey !== "Released" ||
            store.state.pgmData.programStatusKey !== "Cancelled" ||
            store.state.pgmData.programStatusKey !== "SalesSubmitted")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    const handleWording = (acitve: number) => {
      activeWording.value = acitve;
      init();
    };
    const handleDownload = () => {
      const params = getParams();
      getReplacedWordingPdf({ params: params }).then((res) => {
        if (res !== null) {
          const downloadElement = document.createElement("a");
          downloadElement.href = res.fileUrl;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
        }
        message.success("Download request submit successful.");
      });
    };
    // 点击删除文件名
    const handleDel = () => {
      const params = getParams();
      deleteReplacedWordingPdf({ params: params }).then(() => {
        message.success("Delete Successed!");
        fileName.value = "";
      });
    };
    const handleWordingDownload = () => {
      const params = getParams();
      blobDownload({
        url: "/pgapi/program/wording/preview_pdf",
        params: { ...params, image: false },
      });
    };

    const initVisible = ref(false);
    const selectedType = computed(() => typeMap[activeWording.value]);
    const handleInitializeWording = () => {
      initVisible.value = true;
    };

    const handleOk = () => {
      const params = getParams();
      wordingInit(params).then(() => {
        initVisible.value = false;
        message.success(`${selectedType.value} initialized successfully!`);
      });
    };

    onMounted(() => {
      init();
    });
    return {
      previewHtml,
      handleClose,
      handleEditWording,
      handleCompareWording,
      baseURL,
      headers,
      activeWording,
      handleWording,
      handleCloseUpload,
      handleUpload,
      fileList,
      visible,
      isShowEdit,
      handleDownload,
      fileName,
      handleDel,
      handleWordingDownload,
      handleInitializeWording,
      handleOk,
      selectedType,
      initVisible,
    };
  },
});
